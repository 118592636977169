import React, { FC, lazy } from 'react';
import {
  withRouter,
  Switch,
  Redirect,
  RouteComponentProps
} from 'react-router-dom';

import AuthFormRoute from '@/auth/core/auth-form-route/AuthFormRoute';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';

import Login from '@/auth/pages/login/Login';
import Logout from '@/auth/pages/logout/Logout';
import Route from '@/components/router/Route';
import AuthCallback from '@/auth/pages/pro-sante-connect/auth-callback/AuthCallback';
import SignupCallback from '@/auth/pages/pro-sante-connect/signup-callback/SignupCallback';

const Forgot = lazy(() => import('@/auth/pages/forgot/Forgot'));
const Reset = lazy(() => import('@/auth/pages/reset/Reset'));
const CreateChoose = lazy(() => import('@/auth/pages/create-choose/CreateChoose'));
const CreatePatient = lazy(() => import('@/auth/pages/create-patient/CreatePatient'));
const CreateMedic = lazy(() => import('@/auth/pages/create-medic/CreateMedic'));
const Subscription = lazy(() => import('@/auth/pages/subscription/Subscription'));
const UploadIdentityDocument = lazy(() => import('@/auth/pages/upload-identity-documents/UploadIdentityDocument'));
const SubscriptionSuccess = lazy(() => import('@/auth/pages/subscription-success/SubscriptionSuccess'));

const AutoLogin = lazy(() => import('@/auth/pages/auto-login/AutoLogin'));
const MedicComplete = lazy(() => import('@/auth/pages/medic-complete/MedicComplete'));
const CGUUpdates = lazy(() => import('@/auth/pages/cgu-updates/CGUUpdates'));
const AccountEnable = lazy(() => import('@/auth/pages/account-enable/AccountEnable'));
const NewsletterUnsubscribe = lazy(() => import('@/auth/pages/newsletter-unsubscribe/NewsletterUnsubscribe'));

/**
 * Auth main component && route handler
 * TODO regroup all subscriptions routes in same sub directory / sub component
 */
const Auth: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <AuthFormRoute path={`${match.path}/login`} exact>
        <Login
          simple={false} />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/forgot`} exact>
        <Forgot />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/pro_sante_connect/login/callback`} exact>
        <AuthCallback />
      </AuthFormRoute>
      <Route path={`${match.path}/pro_sante_connect/refresh/callback`} exact>
        <AuthCallback refresh={true} />
      </Route>
      <AuthFormRoute path={`${match.path}/pro_sante_connect/signup/callback`} exact>
        <SignupCallback />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/reset/:token`} exact>
        <Reset />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/create/choose`} exact>
        <CreateChoose />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/create/medic`} exact>
        <CreateMedic />
      </AuthFormRoute>
      <AuthFormRoute path={`${match.path}/create/patient`} exact>
        <CreatePatient />
      </AuthFormRoute>
      <PrivateRoute path={`${match.path}/medic/complete`} exact>
        <MedicComplete />
      </PrivateRoute>
      <PrivateRoute path={`${match.path}/account/enable`} exact>
        <AccountEnable />
      </PrivateRoute>
      <Route path={`${match.path}/subscription/success`} exact>
        <SubscriptionSuccess />
      </Route>
      <Route path={`${match.path}/subscription/:cancelled(cancel)?`} exact>
        <Subscription />
      </Route>
      <Route path={`${match.path}/medic/identity_documents`} exact>
        <UploadIdentityDocument />
      </Route>
      <Route path={`${match.path}/logout`} exact >
        <Logout />
      </Route>
      <Route path={`${match.path}/autologin/:token`} exact>
        <AutoLogin />
      </Route>
      <PrivateRoute path={`${match.path}/cgu/update`} exact>
        <CGUUpdates />
      </PrivateRoute>
      <PrivateRoute path={`${match.path}/newsletter/unsubscribe`} exact>
        <NewsletterUnsubscribe />
      </PrivateRoute>
      <Redirect
        to={`${match.url}/login`}
        from={`${match.url}`}
      />
    </Switch>
  );
};

export default withRouter(Auth);
