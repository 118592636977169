import { getCookie, removeCookie, setCookie } from '@/utils/cookies';
import { getJwtExpiry } from '@/auth/hooks/useLogIn.utils';

import { getEnv } from '@/utils/importMeta';

const getKeyPrefix = () => {
  const env = getEnv('VITE_ENV');

  if(env === 'production') {
    return '';
  }

  if(env === 'staging') {
    return 'stage_';
  }

  if(env === 'development') {
    return 'dev_';
  }

  return `${env}_`;

};

export const StorageKeys = {
  TOKEN: `${getKeyPrefix()}jwt_token`,
  IMPERSONATION_KEY: `${getKeyPrefix()}impersonation_id`,
  REFRESH_TOKEN: `${getKeyPrefix()}jwt_refresh_token`,
  SYNAPSE_TOKEN: `${getKeyPrefix()}synapse_token`,
  USERNAME: 'username',
  MERCURE_TOKEN: 'jwt_mercure_token',
  CONSTANTS: `${getKeyPrefix()}api_constants`,
  FEATURES: `${getKeyPrefix()}feature_flag`,

  // discussions
  DISCUSSIONS__ENABLE_SIGNATURE: 'discussion.enableSignature',
  DISCUSSIONS__ENTER_TO_SEND: 'discussion.enterToSend',

  // emails
  EMAIL__ENABLE_SIGNATURE: 'email.enableSignature',
  EMAIL__ENTER_TO_SEND: 'email.enterToSend',
  EMAIL__IMPERSONATED_USER: 'email.impersonated_user',

  // medical document
  MEDICAL_DOCUMENT__FILLED_FIELDS_KEY: 'medical_document.hide_filled_fields',
  MEDICAL_DOCUMENT__REMOVE_EMPTY_PAGES: 'medical_document.remove_empty_pages',

  // Calendar
  CALENDAR__SELECTED_USERS: 'instamed_calendar_filter_calendar_selector',
  CALENDAR__USERS: 'instamed_calendar_filter_calendar_users',
  CALENDAR__DEFAULT_VIEW: 'instamed_calendar_default_view',

  // Patients list
  PATIENTS_LIST__SEARCH_BY: 'patients_list__search_by',
  PATIENTS_LIST__ACTIVE_TAB: 'patients_list__active_tab',
  PATIENTS_LIST__SEARCH_VALUE: 'patients_list__search_value',
};

type StorageKeyKeys = keyof typeof StorageKeys;
export type StorageKeyValues = typeof StorageKeys[StorageKeyKeys];

type CookieType = {
  expiry : Date | number,
  value : string
}

export type Storage = {
  [id in StorageKeyValues]?: string | CookieType;
};

/**
 * store auth infos in local storage
 */
export const saveItems =
  (args: Storage): void => {
    Object.values(StorageKeys).forEach(key => setItem(key,args[key]));
  };

/**
 * get localstorage value
 */
export const getItem = (key: StorageKeyValues): string | null => {

  const cookie = getCookie(key);

  if(cookie) {
    return cookie;
  }

  try {
    // Legacy
    return localStorage.getItem(key);
  } catch {
    return null;
  }

};


export const setJSON = (key: StorageKeyValues, value: unknown): void => {

  try {
    setItem(key, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

};

export const getJSON = <T>(key: StorageKeyValues): T | null => {

  const value = getItem(key);

  if (value === null) {
    return null;
  }

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

export const getBooleanItem = (key: StorageKeyValues, defaultValue = false): boolean => {

  const value = getItem(key);

  if (value === null) {
    return defaultValue;
  }

  return value === 'true' || value === '1';

};


export const setJWT = (key : StorageKeyValues,value : string) => {
  setItem(key, {
    value,
    expiry: getJwtExpiry(value)
  });
};


/**
 * replace a simple value
 */
export const setItem = (key: StorageKeyValues, value: string | undefined | CookieType): void => {

  if(!value) {
    localStorage.removeItem(key);
    removeCookie(key);
    return;
  }

  if(typeof value === 'string') {
    localStorage.setItem(key, value);
    return;
  }

  setCookie(key, value.value, value.expiry);

};

/**
 * remove item
 */
export const removeItem = (key: StorageKeyValues): void => {
  localStorage.removeItem(key);
  removeCookie(key);
};

export const clearAll = (): void => {

  [
    StorageKeys.TOKEN,
    StorageKeys.REFRESH_TOKEN,
    StorageKeys.MERCURE_TOKEN,
    StorageKeys.IMPERSONATION_KEY
  ].forEach(removeItem);

};

export default {
  getItem,
  saveItems,
  setItem,
  setJWT,
  removeItem,
  clearAll,
  getBooleanItem,
  setJSON,
  getJSON
};
