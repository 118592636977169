import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import BaseLink, { LinkButtonProps } from '@ui/atoms/texts/link-button/LinkButton';


const LinkButton: FC<LinkButtonProps> = ({ href, onClick,children, ...rest }) => {

  const history = useHistory();

  if(!onClick && href?.startsWith('/v2/')) {
    onClick = (e) => {
      href = href?.replace('/v2/','/') as string;
      history.push(href);
      e.preventDefault();
    };
  }

  return (<BaseLink
    onClick={onClick}
    href={href}
    {...rest}
  >{children}
  </BaseLink>);
};


export default LinkButton;