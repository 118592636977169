import { addUrlParameters, parseId, UrlArg } from '@/utils/Api';
import { URL_LEGACY } from '@/constants';

export const URLS = {
  home: '/dispatch',
  doctor: {
    settings: '/medic-parametres',
    pdf_templates: '/pdf_template/list',
    update_subscription: '/subscriptions/update'
  },
  patient: {
    profile: '/patients/user/:userId',
    documents: '/patients/user/:userId/documents',
    medicalTeam: '/patients/user/:userId/medical_team',
    settings: '/profil/editer'
  },

  security: '/profile/security',
  notifications: '/settings/notifications',
  test_media: '/test-medias',

  new_consultation: '/patients/manage?new_consultation=true',

  admin: {
    users_list: '/admin/users/list'
  }

};

export const buildSettingsUrl = ( tab : string|undefined ) : string => {
  let url = URLS.doctor.settings;

  if(tab) {
    url += `#${tab}`;
  }

  return buildLegacyUrl(url);
};

export const buildPatientSettingsUrl = (tab : string|undefined) => {

  let url = URLS.patient.settings;


  if(tab) {
    url += `#${tab}`;
  }

  return buildLegacyUrl(url);
};

export const redirectToSettings = (tab : string|undefined) => {
  window.location.assign(buildSettingsUrl(tab));
};

export const buildPatientProfileUrl = (userId : string, params : UrlArg = {}) => {

  userId = parseId(userId) as string;

  return buildLegacyUrl(URLS.patient.profile, { userId, ...params });
};

export const buildPatientMyDocumentUrl = (userId : string, params : UrlArg = {}) => {

  userId = parseId(userId) as string;

  return buildLegacyUrl(URLS.patient.documents, { userId, ...params });
};

export const buildPatientMedicalTeamUrl = (userId : string, params : UrlArg = {}) => {

  userId = parseId(userId) as string;

  return buildLegacyUrl(URLS.patient.medicalTeam, { userId, ...params });
};

/**
 * redirect to login page
 */
export const redirectToLegacy = (path: string,args: Record<string, unknown> = {}): void => {
  window.location.assign(buildLegacyUrl(path,args));
};


export const buildLegacyUrl = (path: string, args: Record<string, unknown> = {}): string => {
  let urlLegacy = URL_LEGACY;
  if (!urlLegacy && window?.location?.origin) {
    urlLegacy = window?.location?.origin;
  }

  const fullPath = (path.startsWith('http') || path.startsWith('blob')) ? path : `${urlLegacy}${path}`;

  return addUrlParameters(fullPath, args);
};
