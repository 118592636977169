import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactGA3 from 'react-ga';
import { useRecoilValue } from 'recoil';

import getSubDomain from '@/utils/getSubDomain';
import { getEnv } from '@/utils/importMeta';
import { MeState } from '@/services/store/store';

const GA3_TRACKING_CODE = getEnv('VITE_GA3_TRACLING_CODE');
const GA_TRACKING_CODE = getEnv('VITE_GA_TRACKING_CODE');

if (GA3_TRACKING_CODE) {
  ReactGA3.initialize(GA3_TRACKING_CODE);
}

if (GA_TRACKING_CODE) {
  ReactGA.initialize(
    GA_TRACKING_CODE
  );
}

const Tracking:FC = () => {
  const location = useLocation();
  const me = useRecoilValue(MeState);

  useEffect(
    () => {
      const dimensions = {
        institution: getSubDomain(),
        role: me?.attributes?.role,
        id: me?.attributes?._id
      };

      GA_TRACKING_CODE && ReactGA.set(dimensions);

      GA3_TRACKING_CODE && ReactGA3.set(dimensions);
    },
    [me]
  );

  useEffect(
    () => {
      GA_TRACKING_CODE && ReactGA.send({
        hitType: 'pageview',
        page: location.pathname
      });

      GA3_TRACKING_CODE && ReactGA3.pageview(location.pathname);
    },
    [location.pathname]
  );

  return null;
};

export default Tracking;
