import { useRecoilState } from 'recoil';
import { ModalType, ProductType, requestErrorAtom } from '@/services/store/store';
import { useCallback } from 'react';



const useRequestError = () => {
  const [requestErrorState, setRequestErrorState] = useRecoilState(requestErrorAtom);

  const openModal = useCallback((modal : ModalType | undefined,redirectUrl ?: string,productType ?: ProductType) => {
    setRequestErrorState({
      modalType: modal,
      redirectUrl,
      productType,
    });
  },[setRequestErrorState]);

  const closeModal = useCallback(() => {
    setRequestErrorState({
      modalType: undefined,
      redirectUrl: undefined,
      productType: undefined,
    });
  },[setRequestErrorState]);

  return {
    openModal,
    closeModal,
    ...requestErrorState,
  };

};


export default useRequestError;