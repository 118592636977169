import { Item, Item as BaseItem, Relationship } from '@/packages/back-end/jsonapi';
import { ADMINS, MEDICAL_STAFF, ROLE } from '@/packages/types';
import Phone from '@/packages/back-end/phone';
import { buildUrl, UrlArg } from '@/utils/Api';
import ServiceEntity from '@/packages/back-end/service-entity';
import Institution from '@/packages/back-end/Institution';
import { OptionType } from '@ui/types';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';
import { DATE_NUMERIC, dateFormat } from '@ui/utils/dateFormat';
import { getConstantType } from '@/utils/hooks/useApiConstant';

const formatter = dateFormat(DATE_NUMERIC);

export type Thumbnails = {
  profilePicture: {
    '50x50': string | null,
    '200x200': string | null,
  }
};

export type TwoFactorMethod = 'sms' | 'email' | 'totp' | 'backup_code' | null;

type User = ServiceEntity & {
  id: string;
  attributes: {
    email?: string;
    username: string;
    name: string,
    fullName: string,
    firstName: string;
    fullLastName?: string;
    lastName: string;
    dateOfBirth?: string | null;
    givenName: string | null;
    role: ROLE,
    futureAppointmentsCount?: number;
    editableProviders?: string[];
    thumbnails: Thumbnails;
    plainPassword?: string;
    lastLogin?: string;
    title?: string;
    enabledModules: Record<string, boolean>;
    providers: string[];
    twoFactorMethod?: TwoFactorMethod;
    rights?: {
      view_profile: boolean,
      call: boolean,
      update: boolean
      send_message: boolean
    }
  },
  relationships?: {
    phoneNumber?: {
      data?: Relationship | Phone | null
    }
    phones?: Item<(Phone | Relationship)[]>;
    phone?: Item<Phone | Relationship>;
  }
}

export type MeUser = User & {
  attributes: {
    profilePicture: string | null,
    CGUAgreed: boolean,
    email: string
    fullLastName: string,
    createdDate: string,
    intercomHash: string,
    aliasName: string,
    timezone: string,
    enabledModules: {
      [key: string]: boolean
    }
  },
  relationships: {
    institutions?: Item<(Relationship | Institution)[]>
  }
}


export default User;

export type MeItem = BaseItem & {
  data: MeUser
}


export const ME: () => string = () => buildUrl('users', 'me', { 'jsonapi': true });
export const GET_ITEM: (id : string | number|undefined ) => string = (id) => buildUrl('users', id);

export const isMedicalStaff = (user: User| MeUser | undefined): boolean => {

  return isRoles(user,MEDICAL_STAFF);
};

export const isAdmin = (user : User | MeUser | undefined) : boolean => {

  return isRoles(user,ADMINS);
};

export const isDoctor = (user : User | MeUser | undefined | null) : boolean => {

  return isRoles(user,[ROLE.MEDECIN]);
};

export const isDoctorAssistant = (user : User | MeUser | undefined) : boolean => {

  return isRoles(user,[ROLE.COORDINATOR,ROLE.PARAMEDICAL]);
};


const isRoles = (user : User| MeUser | undefined | null, roles : ROLE[]) : boolean => {
  if(!user) {
    return false;
  }
  return roles.includes(user?.attributes?.role);
};

export enum URL_CONTEXTS {
  MY_MEDICS = 'MY_MEDICS',
  CALENDAR_MANAGEMENT = 'CALENDAR_MANAGEMENT',
  CALENDAR = 'CALENDAR'
}

export const GET_COLLECTION = (args ?: UrlArg) => {
  return buildUrl('users', null, args);
};


export const buildOption = (item: User | MeUser): OptionType<User|MeUser> => {

  return {
    value: item.id,
    label: item.attributes.fullName,
    avatar: getAvatarProps({ user: item.attributes as UserType, colorLength: 10 }),
    data: item
  };

};

export const buildOptionWithEmail = (item: User): OptionType<User> => {

  return {
    ...buildOption(item),
    subtitle: item.attributes.email
  };

};

export const buildOptionWithInstitution = (item: User): OptionType<User> => ({
  value: item.id as string,
  label: item.attributes.name,
  avatar: getAvatarProps({ user: item.attributes as UserType, colorLength: 10 }),
  subtitle: (item.relationships?.institution as Item<Institution>)?.data?.attributes?.name as
    | string
    | undefined,
  data: item,
});


export const buildOptionWithInstitutionOrDateOfBirth = (item: User,getConstant : getConstantType): OptionType<User> => {

  let subtitle;

  if(isPatient(item)) {
    subtitle = typeof item.attributes.dateOfBirth === 'string' ? formatter(item.attributes.dateOfBirth) : undefined;
  } else {
    subtitle = (item.relationships?.institution as Item<Institution>)?.data?.attributes?.name as string | undefined;
  }


  return {
    value: item.id as string,
    label: item.attributes.name,
    group: getConstant('user','role',item.attributes.role) as string | null ?? undefined,
    avatar: getAvatarProps({ user: item.attributes as UserType, colorLength: 10 }),
    subtitle,
    data: item,
  };
};


export const isAdminRole = (user : undefined | User | MeUser | ROLE) : boolean => {
  if(!user) {
    return false;
  }

  if(typeof user !== 'string') {
    user = user.attributes.role;
  }

  return [ROLE.HOSPITAL_ADMIN, ROLE.ADMIN].includes(user);

};

export const isPatient = (user : undefined | User | MeUser) : boolean => {
  return user?.attributes.role === ROLE.PATIENT;
};

export const isParamed = (user : undefined | User | MeUser, includeCoordinator = false) : boolean => {

  if(!user) {
    return false;
  }

  return includeCoordinator ?
    [ROLE.COORDINATOR,ROLE.PARAMEDICAL].includes(user.attributes.role) :
    [ROLE.PARAMEDICAL].includes(user.attributes.role);

};


export const buildOptionWithDateOfBirth = (item: User): OptionType => {

  return {
    value: item.id,
    subtitle: typeof item.attributes.dateOfBirth === 'string' ?
      formatter(item.attributes.dateOfBirth) : undefined,
    label: item.attributes.fullName,
    avatar: getAvatarProps({ user: item.attributes as UserType, colorLength: 10 })
  };

};

export const PUT_ITEM =
  (id: string | number, args?: Record<string, unknown>) => {
    return buildUrl('users', id, args);
  };
export const POST_COLLECTION =
  (args?: Record<string, unknown>) => {
    return buildUrl('users', null, args);
  };



export const getUserLastNames = (user : User) : {givenName : string, maidenName : string | null} => 
{
  const givenName = (() => {

    if(user?.attributes.givenName) {
      return user.attributes.givenName;
    }

    return user?.attributes.lastName ?? '';

  })();

  const maidenName = (() => {
    if(!user?.attributes.givenName) {
      return null;
    }

    if(!user.attributes.lastName) {
      return null;
    }

    if(user.attributes.lastName === user.attributes.givenName) {
      return null;
    }

    return user.attributes.lastName;

  })();

  return {
    givenName,
    maidenName
  };

};