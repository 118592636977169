export enum URLS {
  // account identifiers
  LOGIN = '/auth/login',
  FORGOT = '/auth/forgot',
  RESET = '/auth/reset/%TOKEN%',
  LOGOUT = '/auth/logout',
  // account creation
  CREATE = '/auth/create/choose',
  CREATE_MEDIC = '/auth/create/medic',
  MEDIC_IDENTITY_DOCUMENTS = '/auth/medic/identity_documents',
  CREATE_PATIENT = '/auth/create/patient',
  // choose subscription
  CHOOSE_SUBSCRIPTION = '/auth/subscription'
}

export const API = {
  LOGIN: '/api/v2/login',
  AUTO_LOGIN: '/api/v2/autologin',
  CREATE_MEDIC: '/api/v2/signup/medics',
  MEDIC_COMPLETE: '/api/v2/signup/medics/%PROFILE_ID%',
  SUBSCRIPTION_CHECKOUT: '/api/v2/subscriptions/checkout',
  PRODUCTS: '/api/v2/products/legacy',
  RESET_VALIDATE: '/api/v2/users/reset-password/validate',
  RESET_PASSWORD: '/api/v2/users/reset-password/reset',
  ACCOUNT_ENABLE: '/api/v2/users/me/enable',
};
