import React, { useEffect, useState } from 'react';
import H1 from 'instamed-styleguide/ui/atoms/texts/base/H1';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  @keyframes flicker {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #cursor {
    display: inline-block;
    margin-top: 3px;
    animation: flicker .50s infinite;
  }
`;

const createRandomOrder = (max: number) => {
  const arr = [];
  for (let i = 0; i < max; i++) {
    arr.push(i);
  }
  return arr.sort(() => Math.random() - 0.5);

};

const TypingLoginLabels = () => {
  const { t } = useTranslation('auth');
  const [displayResponse, setDisplayResponse] = useState('');
  const [completedImplementing, setCompletedImplementing] = useState(false);

  const [randomOrder] = useState<number[]>(createRandomOrder(9));

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    const printLabels = (historyToSee: number) => {
      let i = 0;
      setCompletedImplementing(false);

      const stringResponse = t(`typing_labels.label_${randomOrder[historyToSee]}`);
      historyToSee++;

      intervalId = setInterval(() => {

        i+=3;

        setDisplayResponse(stringResponse.slice(0, i));

        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedImplementing(true);
          if (historyToSee < 9) {
            return setTimeout(() => {
              printLabels(historyToSee);
            }, 2000);
          } else {
            return setTimeout(() => {
              printLabels(0);
            }, 2000);
          }
        }
      }, 80);
    };

    printLabels(0);

    return () => clearInterval(intervalId);
  }, [randomOrder,t]);

  return (
    <Container className='flex z-10 flex-col justify-start mx-auto mt-[25%] w-[75%] h-[50%]'>
      <H1 className='!text-4xl !font-semibold'>{t('typing_labels.title')}</H1>
      <p className='text-4xl'>
        {displayResponse}
        {!completedImplementing && (
          <span className='ml-2 w-[3px] h-7 bg-gray-900' id='cursor' />
        )}
      </p>
    </Container>
  );
};

export default TypingLoginLabels;
